import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SvgIcon } from "@mui/material";
import { tokens } from "src/locales/tokens";
import { paths } from "src/paths";
import HomeIcon from "src/components/conversations/home-icon";
import QuestionIcon from "src/components/conversations/question-icon";
import MessageIcon from "src/components/conversations/message-icon";
import ClaimIcon from "src/components/conversations/claim-icon";
import ChatIcon from "src/components/conversations/chat-icon";
import ReturnIcon from "src/components/conversations/return-icon";

export const useSections = () => {
  const { t } = useTranslation();
  
  return useMemo(() => {
    return [
      {
        items: [
          {
            title: t(tokens.nav.home),
            path: paths.dashboard.home,
            disabled: false,
            icon: (
              <SvgIcon fontSize="small">
                <HomeIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.questions),
            path: paths.dashboard.questions,
            disabled: false,
            icon: (
              <SvgIcon fontSize="small">
                <QuestionIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.messages),
            path: paths.dashboard.messages,
            disabled: false,
            icon: (
              <SvgIcon fontSize="small">
                {/* <MessageChatSquareIcon /> */}
                <MessageIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.chats),
            path: paths.dashboard.chats,
            disabled: false,
            icon: (
              <SvgIcon fontSize="small">
                {/* <MessageChatSquareIcon /> */}
                <ChatIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.claims),
            path: paths.dashboard.claims,
            disabled: false,
            icon: (
              <SvgIcon fontSize="small">
                {/* <MessageChatSquareIcon /> */}
                <ClaimIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.returns),
            path: paths.dashboard.returns,
            disabled: false,
            icon: (
              <SvgIcon fontSize="small">
              {/* <MessageChatSquareIcon /> */}
              <ReturnIcon />
            </SvgIcon>
            ),
          },
        ],
      },
    ];
  }, [t]);
};
