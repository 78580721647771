import { tokens } from "../tokens";

export const pt = {
  [tokens.nav.home]: "Home",
  [tokens.nav.account]: "Minha conta",
  [tokens.nav.questions]: "Perguntas",
  [tokens.nav.messages]: "Mensagens",
  [tokens.nav.claims]: "Reclamações",
  [tokens.nav.returns]: "Devoluções",
  [tokens.nav.chats]: "Chat",
};
