import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import FileDropzone from "./file-dropzone";
import { api } from "src/services/api";

const MAX_FILES = 10;
const MAX_IMAGE_SIZE = 5 * 1024 * 1024;
const MAX_VIDEO_SIZE = 100 * 1024 * 1024;

const DropzoneComponent = ({
  s3Files,
  setS3Files,
  user,
  selectedReturnId,
  setLoading,
  allowVideos,
  updateAttachmentStatus,
  setUploadingFiles,
}) => {
  const [existingImages, setExistingImages] = useState(0);

  useEffect(() => {
    const fetchExistingImages = async () => {
      try {
        setLoading(true);
        const attachmentsPath = `tenants/${user.customer}/returnAttachments/${selectedReturnId}/`;
        const response = await api.get(`/v3/bucket/list/?path=${attachmentsPath}`);
        const imageCount = response?.data?.Contents
          ? response?.data?.Contents.filter(
              (item) =>
                item.key.endsWith(".jpeg") ||
                item.key.endsWith(".jpg") ||
                item.key.endsWith(".png")
            ).length
          : 0;

        setExistingImages(imageCount);
      } catch (error) {
        console.error("Erro ao carregar anexos do S3:", error);
        toast.error("Erro ao carregar anexos", {
          position: "bottom-right",
        });
      } finally {
        setLoading(false);
      }
    };
    fetchExistingImages();
  }, [user.customer, selectedReturnId, setLoading]);

  const errorMessage = allowVideos
    ? "Você pode enviar no máximo 10 imagens e 1 vídeo"
    : "Você pode enviar no máximo 10 imagens";
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      ...(allowVideos && { "video/mp4": [".mp4"] }),
    },
    onDrop: async (acceptedFiles) => {
      setLoading(true);
      if (s3Files.length >= MAX_FILES) {
        toast.error(errorMessage, {
          position: "bottom-right",
        });
        setLoading(false);
        return;
        
      }

      if (existingImages >= MAX_FILES) {
        toast.error(errorMessage, {
          position: "bottom-right",
        });
        setLoading(false);
        return;
      }

      const imageFiles = acceptedFiles.filter((file) =>
        file.type.startsWith("image/")
      );
      const videoFiles = acceptedFiles.filter((file) =>
        file.type.startsWith("video/")
      );

      const totalImages =
        s3Files.filter((file) => file.type && file.type.startsWith("image/"))
          .length + imageFiles.length;
      const totalVideos =
        s3Files.filter((file) => file.type && file.type.startsWith("video/"))
          .length + videoFiles.length;

      if (!allowVideos && totalVideos > 0) {
        toast.error("Vídeos não são permitidos na revisão da devolução", {
          position: "bottom-right",
        });
        setLoading(false);
        return;
      }

      if (totalImages > MAX_FILES) {
        toast.error(errorMessage, {
          position: "bottom-right",
        });
        setLoading(false);
        return;
      }

      if (
        acceptedFiles.some(
          (file) =>
            file.size >
            (file.type.startsWith("image/") ? MAX_IMAGE_SIZE : MAX_VIDEO_SIZE)
        )
      ) {
        toast.error(
          "Cada imagem pode ter no máximo 5MB e cada vídeo no máximo 100MB.",
          {
            position: "bottom-right",
          }
        );
        setLoading(false);
        return;
      }

      const filesWithPreview = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      if (setUploadingFiles) {
        setUploadingFiles((prev) => [
          ...prev,
          ...filesWithPreview.map((file) => file.name),
        ]);
      }

      
      try {
        const uploadPromises = filesWithPreview.map(async (file) => {

          const formData = new FormData();
          formData.append("file", file);
          formData.append("directory", `returnAttachments/${selectedReturnId}`);

          const response = await api.post(`/v3/bucket/save`, formData);
          const { path } = response.data;
          const url = `https://assets-s3.weesutech.com.br/${path}`;

          return {
            name: file.name,
            url,
            key: path,
            type: file.type.split("/").pop(),
            preview: file.preview,
          };
        });
        
        const uploadedFiles = await Promise.all(uploadPromises);
        setS3Files((prevFiles) => {
          const newFiles = [...prevFiles, ...uploadedFiles];
          const totalImagesAfterUpload = newFiles.filter(
            (file) => file.type && file.type.startsWith("image/")
          ).length;
          if (totalImagesAfterUpload > MAX_FILES) {
            toast.error(errorMessage, {
              position: "bottom-right",
            });
            return prevFiles;
          }
          return newFiles;
        });
        toast.success("Arquivos enviados com sucesso");
        await updateAttachmentStatus(selectedReturnId);
      } catch (error) {
        toast.error("Erro ao enviar os arquivos. Tente novamente.");
      } finally {
        if (setUploadingFiles) {
          setUploadingFiles((prev) =>
            prev.filter(
              (fileName) =>
                !filesWithPreview.map((file) => file.name).includes(fileName)
            )
          );
        }
        setLoading(false);
      }
    },
  });

  return (
    <FileDropzone
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      messageBox={
        allowVideos ? "Tipos de arquivos suportados: " : "No máximo 25MB e em"
      }
      acceptedFormats={
        allowVideos
          ? [".jpg", ".jpeg", ".png", ".mp4"]
          : [".jpg", ".jpeg", ".png"]
      }
    />
  );
};

export default DropzoneComponent;
