import React from "react";
import {
  Drawer,
  Box,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  Stack,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Scrollbar } from "src/components/scrollbar";
import { OrderItem } from "src/sections/dashboard/chat/order-drawer/order-item";
import { ItemsWrapper, Row, Spacing } from "src/sections/dashboard/chat/order-drawer/style";

const MultiplesItemsComponent = ({ open, onClose, items, loading }) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: 500, padding: 3, overflow: 'auto' },
      }}
    >
    <Stack px={1} py={2}>
    <>
      <Row justifyContent="space-between">
          <Typography variant="h6">Detalhes da devolução</Typography>
          <Tooltip title="Fechar">
            <IconButton
              sx={{ margin: 0, padding: 0 }}
              color="inherit"
              onClick={onClose}
            >
              <CloseRoundedIcon />
            </IconButton>
          </Tooltip>
        </Row>
           <Scrollbar
             sx={{
               height: "100%",
               "& .simplebar-content": {
                 height: "100%",
               },
             }}
           >
          {items?.map((item, idx) => (
            <Stack gap={2}>
              <Spacing direction="vertical" size="0.50rem" />
              <ItemsWrapper>        
                <OrderItem
                  key={idx}
                  value={item}
                  loading={loading}
                  isLast={true}
                />   
              </ItemsWrapper>
            </Stack> 
          ))}
         </Scrollbar>
        </>
        </Stack>
    </Drawer>
  );
};

export default MultiplesItemsComponent;
