import React from "react";
import ShopeeRefund from "src/pages/return/components/shopee/shopee-refund";
import MercadoLivreRefound from "./mercadolivre-refound";

const Refound = ({ showRefound, setShowRefound, activeChat, setUpdatedOnReview }) => {
  const handleClose = () => {
    setShowRefound(false);
  };

  return (
    <>
      {activeChat?.platform_name === "Shopee" ? (
        <ShopeeRefund open={showRefound} onClose={handleClose} activeChat={activeChat} setUpdatedOnReview={setUpdatedOnReview} />
      ) : (
        <MercadoLivreRefound open={showRefound} onClose={handleClose} activeChat={activeChat} setUpdatedOnReview={setUpdatedOnReview} />
      )}
    </>
  );
};

export default Refound;
