export const paths = {
  index: "/auth/login",
  auth: {
    login: "/auth/login",
    register: "/auth/register",
    confirmation: "/auth/confirmation",
    passwordRecovery: "/auth/forgot_password",
    passwordReset: "/auth/password_reset",
  },
  dashboard: {
    index: "/v1",
    questions: "/v1/questions",
    messages: "/v1/messages",
    claims: "/v1/claims",
    returns: "/v1/returns",
    chats: "/v1/chats",
    account: "/v1/account",
    billing: "/v1/account?tab=billing",
    home: "/v1/home",
  },
  billing: "/billing",
  401: "/401",
  404: "/404",
  500: "/500",
};
