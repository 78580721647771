import React from "react";
import {
  Modal,
  Box,
  Typography,
  Stack,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import toast from "react-hot-toast";
import { api } from "src/services/api";

const AwaitReturnModal = ({ open, close, returnItem }) => {

  const handleClose = () => {
    close(false);
  };

  const handleSendAwaitReturn = async () => {
    try {
      const response = await api.post(
        `/v1/chats/sendOffer/${returnItem?.id}`,
        {
          claim_id: returnItem?.claim_id,
          proposed_solution: "RETURN_REFUND",
          proposed_adjusted_refund_amount: 0
        }
      );
      if (response?.status === 200) {
        toast.success("Aguardar devolução enviado!", {
          position: "bottom-right",
        });
        handleClose();
      } else if (response?.status === 204) {
        toast.error(
          "Esta ação não foi aceita pela Shopee, tente novamente mais tarde.",
          {
            position: "bottom-right",
          }
        );
        handleClose();
      }
    } catch (error) {
      toast.error("Erro ao aguardar devolução.", {
        position: "bottom-right",
      });
      handleClose();
    }
  }; 

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          p: 4,
          bgcolor: "background.paper",
          borderRadius: "16px",
          maxWidth: 400,
          width: "90%",
          boxShadow: 24,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6" component="h2">
            Aguardar a devolução
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>

        <Typography variant="body2" sx={{ mt: 2 }}>
          Ao clicar no botão "Confirmar", o comprador devolverá o(s) item(s)
          para você. Você ainda pode oferecer o reembolso total ou, após a
          devolução do produto, abrir uma disputa se tiver algum problema com os
          itens retornados.
        </Typography>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          sx={{ mt: 4 }}
        >
          <Button variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="contained" color="primary" onClick={handleSendAwaitReturn}>
            Confirmar
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default AwaitReturnModal;
