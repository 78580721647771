import React, { useState } from "react";
import { Box, Typography, Stack, Divider, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ExpandableDetails = ({ title, totalValue, details, renderDetail }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ cursor: "pointer", width: "100%" }}
        onClick={handleToggle}
      >
        <Typography variant="subtitle1" sx={{ flex: 1 }}>
          {title}:
        </Typography>
        <Typography
          variant="subtitle1"
          color={title === "Tarifas" ? "error.main" : ""}
          sx={{ marginLeft: "auto", marginRight: "0.5rem" }}
        >
          {title === "Tarifas" ? `- ${totalValue}` : `${totalValue}`}
        </Typography>
        <IconButton size="small">
          <ExpandMoreIcon />
        </IconButton>
      </Stack>

      {expanded && (
        <Box mt={2} pl={2}>
          {Object.entries(details).map(([key, detail], index) => (
            <Box key={key} mb={2}>
              {renderDetail(detail, key)}
              {index < Object.entries(details).length - 1 && (
                <Divider sx={{ my: 1 }} />
              )}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default ExpandableDetails;
